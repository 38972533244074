import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"

const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <Seo title={t("pageTitlePrefix") + t("notFound.title")} />
      <div className="container">
        <div className="my-5">
          <h1>{t("notFound.title")}</h1>
          <p>{t("notFound.text")}</p>
          <Link to="/">{t("notFound.link")}</Link>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        fileAbsolutePath: { ne: "/images/" }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
